<script>
import { UserRoleType } from '@/utils/enums/UserRoleType';
import { mapState } from 'vuex';
export default {
  name: 'layout-profile',

  data() {
    return {
      userRoleType: UserRoleType,
      items: [
        { title: 'Meus dados', icon: 'mdi-account', path: '/profile/detail' },
        { title: 'Senha', icon: 'mdi-lock', path: '/profile/change-password' },
      ],
    };
  },

  computed: {
    ...mapState('auth', {
      currentUser: (state) => state.user,
    }),
  },
};
</script>

<template>
  <div class="mt-10">
    <h4 class="pb-2">Perfil</h4>
    <v-row>
      <v-col sm="3">
        <v-card class="mx-auto" max-width="300" tile>
          <div class="text-center pa-3">
            <v-avatar size="98">
              <img v-if="currentUser.avatarUrl" v-auth-image="currentUser.avatarUrl" style="object-fit: cover" />
              <v-icon v-else size="98">mdi-account-circle</v-icon>
            </v-avatar>
            <h4>{{ currentUser.name }}</h4>
            <small class="light-blue--text">
              <i>{{ userRoleType[currentUser.role] }}</i>
            </small>
          </div>
          <v-list class="pa-0">
            <v-list-item v-for="(item, i) in items" :key="i" :to="item.path">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col sm="9">
        <vue-page-transition name="fade-in-up">
          <router-view />
        </vue-page-transition>
      </v-col>
    </v-row>
  </div>
</template>
